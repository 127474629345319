import React from "react";

export default function PlayIcon(props: React.SVGProps<SVGSVGElement>) {
  return <svg
    viewBox="0 0 11 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1.125 0.875L9.875 6.5L1.125 12.125V0.875Z" fill="white" stroke="#FAFAFA" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
}
